import React, { useState } from "react";
import moment from "moment";
import "./App.css";

const App = () => {
  // State variables for each query parameter
  const [apiUrl, setApiUrl] = useState("api.usestoryteller.com");
  const [apiKey, setApiKey] = useState("4ed98ae9-4e80-4849-b727-70a1b68dccef");
  const [clientPlatform, setClientPlatform] = useState("iOS");
  const [clientVersion, setClientVersion] = useState("9.7.0");

  const [collectionId, setCollectionId] = useState("opening-week-fire-moments");

  // User Attributes
  const [favoriteTeam, setFavoriteTeam] = useState("MEM");
  const [followedTeams, setFollowedTeams] = useState("LAL,LAC");
  const [followedPlayers, setFollowedPlayers] = useState("201939,2544");
  const [followedDrivers, setFollowedDrivers] = useState("");

  // Boost Config
  const [creationTimeBoostFactor, setCreationTimeBoostFactor] = useState("50");
  const [likeCountBoostFactor, setLikeCountBoostFactor] = useState("0.0001");
  const [shareCountBoostFactor, setShareCountBoostFactor] = useState("0.0002");
  const [primaryCategoryBoostFactor, setPrimaryCategoryBoostFactor] =
    useState("10");
  const [secondaryCategoryBoostFactor, setSecondaryCategoryBoostFactor] =
    useState("5");
  const [clipCreationTimeLowerBound, setClipCreationTimeLowerBound] =
    useState("");

  const [clipsData, setClipsData] = useState([]);
  const [forYouFollowingEnabled, setForYouFollowingEnabled] = useState(true);
  const [isFollowing, setIsFollowing] = useState(false);

  const forYou = `https://${apiUrl}/api/app/clips/${collectionId}/clips?x-storyteller-api-key=${apiKey}&ClientPlatform=${clientPlatform}&ClientVersion=${clientVersion}`;
  const followingBaseUrl = `https://${apiUrl}/api/app/clips/${collectionId}/clips/following?x-storyteller-api-key=${apiKey}&ClientPlatform=${clientPlatform}&ClientVersion=${clientVersion}`;

  const baseUrl = isFollowing ? followingBaseUrl : forYou;

  const userAttributes = `&userAttributes[favoriteTeam]=${favoriteTeam}&userAttributes[followedTeams]=${followedTeams}&userAttributes[followedPlayers]=${followedPlayers}&userAttributes[followedDrivers]=${followedDrivers}`;

  const boostConfig = `&elasticSearchBoostConfig.creationTimeBoostFactor=${creationTimeBoostFactor}&elasticSearchBoostConfig.likeCountBoostFactor=${likeCountBoostFactor}&elasticSearchBoostConfig.shareCountBoostFactor=${shareCountBoostFactor}&elasticSearchBoostConfig.primaryCategoryBoostFactor=${primaryCategoryBoostFactor}&elasticSearchBoostConfig.secondaryCategoryBoostFactor=${secondaryCategoryBoostFactor}&elasticSearchBoostConfig.clipCreationTimeLowerBound=${clipCreationTimeLowerBound}`;

  const handleSearch = async (e) => {
    e.preventDefault();
    const publicApiUrl = `${baseUrl}${userAttributes}${boostConfig}`;

    try {
      const response = await fetch(publicApiUrl);
      const data = await response.json();
      setClipsData(data.clips); // Set the clips array to state

      if (data.forYouFollowingEnabled) {
        setForYouFollowingEnabled(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatAsDate = (dateString) => {
    return dateString.split("T").join(" ").split("+")[0];
  };

  const getTimeDifference = (creationTime) => {
    const creationDate = moment.utc(creationTime);
    const creationTimeBoostCenterDate = moment.utc(moment.now());
    const duration = moment.duration(
      creationDate.diff(creationTimeBoostCenterDate)
    );

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    return `${days}d ${hours}h ${minutes}m`;
  };

  return (
    <div className="container">
      <div className="formContainer">
        <form onSubmit={handleSearch} className="form">
          <button type="submit" className="button">
            Search
          </button>
          {forYouFollowingEnabled && (
            <div className="toggleButtonContainer">
              <button
                className={`toggleButton ${!isFollowing ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  setIsFollowing(false);
                }}
              >
                For You
              </button>
              <button
                className={`toggleButton ${isFollowing ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  setIsFollowing(true);
                }}
              >
                Following
              </button>
            </div>
          )}
          <div className="inputTable">
            <span className="section-header">General</span>
            <InputField label="API URL" value={apiUrl} setValue={setApiUrl} />
            <InputField label="API Key" value={apiKey} setValue={setApiKey} />
            <InputField
              label="Client Platform"
              value={clientPlatform}
              setValue={setClientPlatform}
            />
            <InputField
              label="Client Version"
              value={clientVersion}
              setValue={setClientVersion}
            />
            <InputField
              label="Collection ID"
              value={collectionId}
              setValue={setCollectionId}
            />
            <span className="section-header">User Attributes</span>
            <InputField
              label="Favorite Team"
              value={favoriteTeam}
              setValue={setFavoriteTeam}
            />
            <InputField
              label="Followed Teams"
              value={followedTeams}
              setValue={setFollowedTeams}
            />
            <InputField
              label="Followed Players"
              value={followedPlayers}
              setValue={setFollowedPlayers}
            />
            <InputField
              label="Followed Drivers"
              value={followedDrivers}
              setValue={setFollowedDrivers}
            />
            <span className="section-header">Elasticsearch Boost Factors</span>
            <InputField
              label="Creation Time Boost Factor"
              value={creationTimeBoostFactor}
              setValue={setCreationTimeBoostFactor}
            />
            <InputField
              label="Like Count Boost Factor"
              value={likeCountBoostFactor}
              setValue={setLikeCountBoostFactor}
            />
            <InputField
              label="Share Count Boost Factor"
              value={shareCountBoostFactor}
              setValue={setShareCountBoostFactor}
            />
            <InputField
              label="Primary Category Boost Factor"
              value={primaryCategoryBoostFactor}
              setValue={setPrimaryCategoryBoostFactor}
            />
            <InputField
              label="Secondary Category Boost Factor"
              value={secondaryCategoryBoostFactor}
              setValue={setSecondaryCategoryBoostFactor}
            />
            <InputField
              label="Clip Creation Time Lower Bound"
              value={clipCreationTimeLowerBound}
              setValue={setClipCreationTimeLowerBound}
              placeholder={"E.g. 2023-04-17T00:00:00-00:00"}
            />
          </div>
        </form>
      </div>
      <div className="clipsContainer">
        <div className="clipsGrid">
          {clipsData.map((clip, i) => (
            <div key={clip.id} className="clipCard">
              <div className="clipOverlay">
                <img
                  className="clipImage"
                  src={clip.thumbnailUrl}
                  alt={clip.description}
                />
                <div className="clipDescriptionOverlay">
                  <div className="clipDescription">{clip.description}</div>
                  <div className="clipDescription">
                    Likes: {clip.likeCount} Shares: {clip.shareCount}
                  </div>
                  <div className="clipDescription">
                    {clip.categories.map((category, i) => (
                      <span>{category}, </span>
                    ))}
                  </div>
                  <div className="clipDescription">
                    <span>{formatAsDate(clip.creationTime)}</span> -
                    <span>{getTimeDifference(clip.creationTime)}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const InputField = ({ label, value, setValue, placeholder }) => (
  <div className="inputRow">
    <label className="inputLabel">{label}</label>
    <input
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className="inputField"
      placeholder={placeholder}
    />
  </div>
);

export default App;
